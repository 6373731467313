exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-service-tsx": () => import("./../../../src/pages/service.tsx" /* webpackChunkName: "component---src-pages-service-tsx" */),
  "component---src-templates-all-clients-page-index-tsx": () => import("./../../../src/templates/all-clients-page/index.tsx" /* webpackChunkName: "component---src-templates-all-clients-page-index-tsx" */),
  "component---src-templates-all-resources-page-index-tsx": () => import("./../../../src/templates/all-resources-page/index.tsx" /* webpackChunkName: "component---src-templates-all-resources-page-index-tsx" */),
  "component---src-templates-blog-article-index-tsx": () => import("./../../../src/templates/blog-article/index.tsx" /* webpackChunkName: "component---src-templates-blog-article-index-tsx" */),
  "component---src-templates-blog-home-page-index-tsx": () => import("./../../../src/templates/blog-home-page/index.tsx" /* webpackChunkName: "component---src-templates-blog-home-page-index-tsx" */),
  "component---src-templates-category-page-index-tsx": () => import("./../../../src/templates/category-page/index.tsx" /* webpackChunkName: "component---src-templates-category-page-index-tsx" */),
  "component---src-templates-client-page-index-tsx": () => import("./../../../src/templates/client-page/index.tsx" /* webpackChunkName: "component---src-templates-client-page-index-tsx" */),
  "component---src-templates-clients-by-page-index-tsx": () => import("./../../../src/templates/clients-by-page/index.tsx" /* webpackChunkName: "component---src-templates-clients-by-page-index-tsx" */),
  "component---src-templates-events-page-index-tsx": () => import("./../../../src/templates/events-page/index.tsx" /* webpackChunkName: "component---src-templates-events-page-index-tsx" */),
  "component---src-templates-generic-page-index-tsx": () => import("./../../../src/templates/generic-page/index.tsx" /* webpackChunkName: "component---src-templates-generic-page-index-tsx" */),
  "component---src-templates-legal-page-index-tsx": () => import("./../../../src/templates/legal-page/index.tsx" /* webpackChunkName: "component---src-templates-legal-page-index-tsx" */),
  "component---src-templates-new-about-payfit-index-tsx": () => import("./../../../src/templates/new-about-payfit/index.tsx" /* webpackChunkName: "component---src-templates-new-about-payfit-index-tsx" */),
  "component---src-templates-new-feature-core-page-index-tsx": () => import("./../../../src/templates/new-feature-core-page/index.tsx" /* webpackChunkName: "component---src-templates-new-feature-core-page-index-tsx" */),
  "component---src-templates-new-features-wall-page-index-tsx": () => import("./../../../src/templates/new-features-wall-page/index.tsx" /* webpackChunkName: "component---src-templates-new-features-wall-page-index-tsx" */),
  "component---src-templates-pillar-page-index-tsx": () => import("./../../../src/templates/pillar-page/index.tsx" /* webpackChunkName: "component---src-templates-pillar-page-index-tsx" */),
  "component---src-templates-premium-service-page-index-tsx": () => import("./../../../src/templates/premium-service-page/index.tsx" /* webpackChunkName: "component---src-templates-premium-service-page-index-tsx" */),
  "component---src-templates-pricing-page-v-3-index-tsx": () => import("./../../../src/templates/pricing-page-v3/index.tsx" /* webpackChunkName: "component---src-templates-pricing-page-v-3-index-tsx" */),
  "component---src-templates-resource-page-index-tsx": () => import("./../../../src/templates/resource-page/index.tsx" /* webpackChunkName: "component---src-templates-resource-page-index-tsx" */),
  "component---src-templates-security-page-index-tsx": () => import("./../../../src/templates/security-page/index.tsx" /* webpackChunkName: "component---src-templates-security-page-index-tsx" */),
  "component---src-templates-service-page-index-tsx": () => import("./../../../src/templates/service-page/index.tsx" /* webpackChunkName: "component---src-templates-service-page-index-tsx" */),
  "component---src-templates-sitemap-page-index-tsx": () => import("./../../../src/templates/sitemap-page/index.tsx" /* webpackChunkName: "component---src-templates-sitemap-page-index-tsx" */),
  "component---src-templates-subcategory-page-index-tsx": () => import("./../../../src/templates/subcategory-page/index.tsx" /* webpackChunkName: "component---src-templates-subcategory-page-index-tsx" */),
  "component---src-templates-thank-you-page-index-tsx": () => import("./../../../src/templates/thank-you-page/index.tsx" /* webpackChunkName: "component---src-templates-thank-you-page-index-tsx" */),
  "component---src-templates-why-payfit-page-index-tsx": () => import("./../../../src/templates/why-payfit-page/index.tsx" /* webpackChunkName: "component---src-templates-why-payfit-page-index-tsx" */)
}

